<template>
    <div class="advantages">
        <h3>Bestarex Advantages</h3>
        <div class="items">
            <div class="item">
                <img src="../../assets/chart.svg" alt="">
                <div class="text">
                    <h4>Compare with others</h4>
                    <p>vida mauris, lacus nisl eget. Imperdiet tempor dictumst ac tellus elementumassa, te</p>
                </div>
            </div>
            <div class="item">
                <img src="../../assets/fingerprint.svg" alt="">
                <div class="text">
                    <h4>No need to Authenticate</h4>
                    <p>vida mauris, lacus nisl eget. Imperdiet tempor dictumst ac tellus elementumassa, te</p>
                </div>
            </div>
            <div class="item">
                <img src="../../assets/wallet.svg" alt="">
                <div class="text">
                    <h4>Easily connect to wallet</h4>
                    <p>vida mauris, lacus nisl eget. Imperdiet tempor dictumst ac tellus elementumassa, te</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Advantages'
    }
</script>

<style lang="scss" scoped>
.items{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 40px;
    row-gap: 20px;
    align-items: center;
}
.item{
    display: flex;
    flex-direction: row;
    width: 30%;
    transition: 800ms;
    &:hover{
        transform: scale(1.05);
    }
}
.advantages{
    z-index: 10;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding: 10px 64px;
}
@media only screen and(max-width:850px){
    .items{
        flex-direction: column;
        .item{
            width: 100%;
            align-items: center;
            justify-content: center;
            .text{
                text-align: center;
            }
        }
    }
}
</style>