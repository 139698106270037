<template>
    <transition name="fade">
        <div class="modal-backdrop" >
          <div class="modal">
              <div class="modal-background">
                  <div class="title">
                      <h4>Connect Wallet</h4>
                      <inline-svg @click="closeModale" class="svg-icon" :src="require('../assets/Icons/closelight.svg')" />
                  </div>
                  <div class="content">
                    <div class="channels">
                      <div v-for="(channel,index) in channels" :key="index" class="rgb-border">
                        <div @click="changeChannel(channel.name)" class="channel-shadow">
                            <div class="channel">
                                <img :src=channel.img alt="">
                                <span>{{channel.name}}</span>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div class="wallets">
                      <template :class="{'disable':wallet.channel!==channel}" v-for="(wallet,index) in wallets"  >
                          <div v-if="wallet.channel==channel" :key="index" class="wallet-rgb-border">
                                <div @click="setWallet" class="wallet">
                                    <img :src=wallet.img alt="">
                                    <span>{{wallet.name}}</span>
                                </div>
                          </div>    
                      </template>
                    </div>
                  </div>
              </div>
          </div>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'Connectwalletmodal',
  data() {
      return {
          channel:'BCS',
          channels:[
              { 
                  img:require('../assets/coins/BITCOIN.png'),
                  name:'BCS'
              },
              { 
                  img:require('../assets/coins/ETHEREUM.png'),
                  name:'ETH'
              }
          ],
          wallets:[
              {
                  img:require('../assets/Wallets/metamask.svg'),
                  name:'MetaMask Wallet',
                  channel:'BCS'
              },
              {
                  img:require('../assets/Wallets/trust.svg'),
                  name:'Trust Wallet',
                  channel:'BCS'
              },
              {
                  img:require('../assets/Wallets/safepal.svg'),
                  name:'SafePal Wallet',
                  channel:'ETH'
              },
              {
                  img:require('../assets/Wallets/connect.svg'),
                  name:'Connect Wallet',
                  channel:'ETH'
              },
              {
                  img:require('../assets/Wallets/coinbase.svg'),
                  name:'CoinBase Wallet',
                  channel:'ETH'
              },
              {
                  img:require('../assets/Wallets/coin98.svg'),
                  name:'Coin98 Wallet',
                  channel:'ETH'
              },
          ]
      
      }
  },
  methods : {
      closeModale() {
        this.$emit('closeWalletModal' , false)
      },
      confirm(){
          this.$router.push({name: 'Swap'})
          this.closeModale();
      },
      changeChannel(ch){
          this.channel=ch;
      },
      setWallet(){
          this.state.username='ali rjbzdeh';
          console.log(this.state.username);
          this.closeModale();
      }
  },
  mounted (){
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.modal,button')) {
              this.closeModale()
            }
            else  {
              return true
            }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
.wallets{
    .wallet{
        background: var(--wallet-modal);
        z-index: -1;
        position: absolute;
        top: -.3%;
        left: .3%;
        width: 99.3%;
        height: 99.3%;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        padding: 16px;
        column-gap: 12px;
        border-radius: 12px;
        min-height: 40px;
        color: var(--title-color);
        img{
            width: 24px;
            height: 24px;
        }
    }
    row-gap: 8px;
    display: flex;
    flex-direction: column;
    min-height: 80px;
    justify-content: space-between;
    align-items: center;
}
.wallet-rgb-border{
        overflow: hidden;
        padding: 30px;
        border-radius: 12px;
        position: relative;
        z-index: 1;
        width: 100%;
        background: linear-gradient(223.32deg, #590C51 -6.08%, rgba(115, 124, 193, 0) 49.43%, #157A75 109.02%);
        height: 100%;
        height: 50px;
        opacity: .7;
        transition: 800ms;
        &:hover{
            cursor: pointer;
            opacity: 1;
            background: linear-gradient(223.32deg, #590C51 -6.08%, rgba(115, 124, 193, 0) 49.43%, #157A75 109.02%);
        }
        }
    .rgb-border{
        border-radius: 12px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        width: 45%;
        background: linear-gradient(223.32deg, #590C51 -6.08%, rgba(115, 124, 193, 0) 49.43%, #157A75 109.02%);
        height: 100%;
        height: 50px;
        opacity: .7;
        transition: 800ms;
        .channel-shadow{
                box-shadow: -8px 8px 48px 0px rgba(250, 36, 124, 0.24);
                position: absolute;
                width: 100%;
                height: 100%;
            }
        &:hover{
            cursor: pointer;
            opacity: 1;
                box-shadow: 8px 8px 8px 0px var(--shadow-box);
            background: linear-gradient(223.32deg, #26E0D6 -6.08%, rgba(115, 124, 193, 0) 49.43%, #BE1AAC 109.02%);
            .channel-shadow{
                
                box-shadow: 8px -8px 48px 0px rgba(38, 224, 214, 0.24);
                }
            .channel{
                box-shadow: -8px 8px 48px 0px rgba(250, 36, 124, 0.24);
            }
        }
        }
.channels{
    .channel{
        background: var(--wallet-modal);
        z-index: -1;
        position: absolute;
        top: -.3%;
        left: .3%;
        width: 99.3%;
        height: 99.3%;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: flex-start;
        padding: 12px;
        column-gap: 12px;
        border-radius: 12px;
        min-height: 40px;
        color: var(--title-color);
        img{
            width: 24px;
            height: 24px;
        }
    }
    display: flex;
    flex-direction: row;
    min-height: 80px;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.content{
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    color: var(--title-color);
    overflow: scroll;
}
.title{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    h4{
        color: var(--global-green);
        font-weight: 900;
        font-family: 'poppins-bold';
        font-size: clamp(18px,2vw,22px);
    }
}
.modal-background{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 20px;
    // background: var(--modal-backdrop);
    // backdrop-filter: blur(300px);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    padding: 24px;
    width: 100%;
    height: 100%;
}
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--modal-backdrop);
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    z-index: 110;
    overflow: scroll;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 430px;
    min-height: 500px;
    height: 85%;
    background: var(--cn-wallet-backdrop);
    backdrop-filter: blur(140px);
    border-radius: 12px;
    box-shadow: 0px 0px 24px var(--shadow-color);
    position: relative;
    // margin: 24px;
    box-sizing: border-box;
    // padding: 8px;
    // padding-bottom:24px ;
    flex-direction: column;
    justify-content: start;
    align-items: flex-start;
  }
  @supports not (backdrop-filter: blur()){
    .modal{
        background:var(--support);
    }
}
</style>