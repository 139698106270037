<template>
    <div class="mainswap">
        <div class="swap-background">
        <Confirmmodal v-if="confirmmodal" @closeConfirmModal="confirmmodal=false" />
            <div class="mainswap-container" :class="{'mainswap-container-suggest':$route.name=='Swapsuggestion'}">
                <Suggestions v-if="$route.name=='Swapsuggestion'" />
                <div class="swap-items">
                    <Swap v-if="$route.name=='Swap' || $route.name=='Swapsuggestion'" @openConfirm="confirmmodal=true" />
                    <div @click="showrate=true" v-if="!showrate" class="rate">
                        <div class="title">
                            <span>Rate</span>
                            <inline-svg :class="{'rotate':showrate}" :src="require('../../assets/Icons/lightDropdown.svg')" alt="" />
                        </div>
                        <div class="numbers">
                            <div class="exchanges">
                                <span>1</span>
                                <span>{{fromcoin}}</span>
                                <span>=</span>
                                <span>{{tocoinprice}}</span>
                                <span>{{tocoin}}</span>
                                <span>(~${{priceindollar}})</span>
                            </div>
                            <inline-svg class="question-icon" :src="require('../../assets/Icons/question.svg')" alt=""/>
                        </div>
                    </div>
                    <div @click="showrate=false" v-if="showrate" class="rate">
                        <div class="title">
                            <span>1 {{fromcoin}} price</span>
                            <inline-svg :class="{'rotate':showrate}" :src="require('../../assets/Icons/lightDropdown.svg')" alt="" />
                        </div>
                            <div class="exchanges">
                                <span>1</span>
                                <span>{{fromcoin}}</span>
                                <span>=</span>
                                <span>{{tocoinprice}}</span>
                                <span>{{tocoin}}</span>
                                <span>(~${{priceindollar}})</span>
                            </div>
                    </div>
                    <div v-if="showrate" class="rate">
                        <div class="title">
                            <span>1 {{tocoin}} price</span>
                        </div>
                            <div class="exchanges">
                                <span>1</span>
                                <span>{{fromcoin}}</span>
                                <span>=</span>
                                <span>{{tocoinprice}}</span>
                                <span>{{tocoin}}</span>
                                <span>(~${{priceindollar}})</span>
                            </div>
                    </div>
                    <div v-if="showrate" class="rate">
                            <span>Minimum Recived</span>
                            <div class="exchanges">
                                <span>15682129.4</span>
                                <span>{{tocoinprice}}</span>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import Footer from '../Public/Footer.vue'
import Confirmmodal from './Confirmmodal.vue'
import Suggestions from './Suggestions.vue'
import Swap from './Swap.vue'
    export default {
        name:'Mainswap',
  components: { Swap, Confirmmodal, Suggestions },
    data(){
        return{
            fromcoin:'USDT',
            priceindollar:1.01,
            tocoinprice:0.9997,
            tocoin:'DAI',
            showrate:false,
            confirmmodal:false,
        }
    },
    mounted(){
        // if(!this.state.username){
        //     this.$router.push({name:'Homepage'})
        // }
    }
    }
</script>

<style lang="scss" scoped>
.rate{
    font-family: 'poppins-light' !important; 
    fill: var(--title-color);
    color: var(--title-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    font-weight: 100;
    position: absolute;
    bottom: -25px;
    font-size: clamp(10px,1vw,12px);
    &:nth-child(3){
        bottom: -45px;
    }
    &:nth-child(4){
        bottom: -65px;
    }
    .title{
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        align-items: center;
        cursor: pointer;
    }
    .numbers{
        .exchanges{
            display: flex;
            flex-direction: row;
            column-gap: 3px;
        }
        // .question-icon{
        //     fill: var(--title-color);
        // }
        display: flex;
        flex-direction: row;
        column-gap: 5px;
    }
}
.swap-items{
    width: 40%;
    height: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: relative;
}
.mainswap{
    overflow: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--background-home);
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.swap-background{
    
    background-size: 100% !important;
    background: var(--colorball-swap);
    backdrop-filter: blur(140px);
    background-repeat: no-repeat;
    background-position: top;
        display: flex;
        row-gap: 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}
.mainswap-container{
    // margin-top: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    justify-content: center;
    column-gap: 24px;
    // max-width: 500px;
}
@media only screen and(max-width:750px){
    .swap-items{
        width: 100%;
    }
}
@media only screen and(max-width:1000px){
    .mainswap-container-suggest{
        padding-top:600px !important;
        padding-bottom: 100px !important;
    }
    // .swap-background{
    //     background-repeat:repeat;
    // }
    .mainswap-container{
        flex-direction: column;
        row-gap: 30px;
        align-items: center;
        justify-content: center;
    }
}
@media only screen and(max-width:600px){
    .rate{
        display: none;
    }
    .swap-background{
        background: none;
    }
}
@media only screen and(max-height:650px){
    .swap-background{
        overflow: scroll;
    }
}
@media only screen and(max-height:550px){
    .mainswap-container{
        padding-top: 300px;
    }
}
@supports not (backdrop-filter: blur()){
    .swap-parent{
        background: unset;
    }
}
</style>