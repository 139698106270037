import { render, staticRenderFns } from "./Coins.vue?vue&type=template&id=77748bb9&scoped=true&"
import script from "./Coins.vue?vue&type=script&lang=js&"
export * from "./Coins.vue?vue&type=script&lang=js&"
import style0 from "./Coins.vue?vue&type=style&index=0&id=77748bb9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77748bb9",
  null
  
)

export default component.exports