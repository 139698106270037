
var Data=[
    {
        img: require('../assets/coins/BITCOIN.png'),
        name: 'BTC',
        remain: 0.0456,
        variant:2.5,
        price:4656.33,
        value:183625927,
        indeal:0.026,
        useable:454,
        year:1400,
        mounth:5,
        day:12,
        hours:13,
        minute:13,
        market:'TRC20',
    },
    {
        img: require('../assets/coins/ETH.png'),
        name: 'ETH',
        remain: 0.0456,
        price:4656.33,
        variant:2.5,
        value:183625927,
        indeal:0.026,
        useable:454,
        year:1400,
        mounth:11,
        day:12,
        hours:13,
        minute:13,
        market:'TRC20',
    },
    {
        img: require('../assets/coins/FLOW.png'),
        name: 'FLOW',
        remain: 0.0456,
        price:4656.33,
        variant:-2.5,
        value:183625927,
        indeal:0.026,
        useable:454,
        year:1400,
        mounth:11,
        day:12,
        hours:13,
        minute:13,
        market:'TRC20',
    },
    {
        img: require('../assets/coins/LITE_COIN.png'),
        name: 'LTC',
        remain: 0.0456,
        price:4656.33,
        variant:-2.5,
        value:183625927,
        indeal:0.026,
        useable:454,
        year:1400,
        mounth:11,
        day:12,
        hours:13,
        minute:13,
        market:'TRC20',
    },

    {
        img: require('../assets/coins/DOGE_COIN.png'),
        name: 'Doge',
        remain: 0.0456,
        variant:2.5,
        price:4656.33,
        value:183625927,
        indeal:0.026,
        useable:454,
        year:1400,
        mounth:11,
        day:12,
        hours:13,
        minute:13,
        // class:'no-border',
    },
    {
        img: require('../assets/coins/CAKE.png'),
        name: 'CAKE',
        remain: 0.0456,
        variant:2.5,
        price:4656.33,
        value:183625927,
        indeal:0.026,
        useable:454,
        year:1400,
        mounth:11,
        day:12,
        hours:13,
        minute:13,
        // class:'no-border',
    },
]
export default Data