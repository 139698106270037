<template>
    <div class="swap container">
        <div class="swap-container">
            <div class="header">
                <span>Suggestions</span>
            </div>
            <div class="suggests">
                <div class="suggest" v-for="(coin,index) in coins" :key="index">
                    <div class="coin">
                        <div class="coin-title">
                            <img :src=coin.img alt="">
                            <span class="name">{{coin.name}}</span>
                        </div>
                        <span>${{coin.useable}}</span>
                    </div>
                    <div class="item">
                        <span>Price</span>
                        <div class="nums">
                            <span>{{coin.price}}</span>
                            <span>$</span>
                            <span>(~{{coin.variant}})</span>
                        </div>
                    </div>
                    <div class="item">
                        <span>TX Cost</span>
                        <div class="nums">
                            <span>{{coin.useable*2}}</span>
                            <span>BNB</span>
                            <span>(~{{coin.indeal}})</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Data from '../../Lib/Fakedata';
    export default {
        name:'Swap',
       data(){
           return{
               coins:Data,
           }
       },
    }
</script>

<style lang="scss" scoped>
.coin-title{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
}
.coin{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-weight: 700;
        font-size: clamp(15px,2vw,18px);
    img{
        height: 24px;
        width: 24px;
    }
}
.suggests{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
    max-height: 450px;
    overflow: scroll;
}
.suggest{
    color: var(--title-color);
    transition: 1s;
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 8px;
    background: var(--swap-background);
    padding: 16px;
    border-radius: 12px;
    .item{
        font-weight: 500;
        font-size: clamp(12px,1vw,14px);
        .nums{
            display: flex;
            flex-direction: row;
            column-gap: 3px;
        }
        width: 100%;
        opacity: .7;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.header{
    color: var(--global-green);
    font-weight: 700;
    font-size: clamp(14px,1.5vw,16px);
}
.swap{
    box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.08);
    align-self: flex-start;
    width:40%;
    border-radius: 8px;
    backdrop-filter: blur(90px);
    background: var(--swap-blur);
        min-height: 540px;
}
.swap-container{
    position: relative;
    align-items: flex-start;
    display: flex;
    justify-content: start;
    flex-direction: column;
    row-gap: 20px;
    height: 100%;
    width: 100%;
    button{
        width: 100%;
    }
}
@media only screen and(max-width:750px){
    .swap{
        width: 100%;
    }
}
@media only screen and(max-width:500px){
 .swap{
     padding: 10px 24px !important;
 }
     .exchange{
         row-gap: 5px;
     } 
}
@media only screen and(max-width:1000px){
    .swap{
        align-self: center;
    }
}
</style>