<template>
    <transition name="fade">
        <div class="modal-backdrop" >
            <div class="modal">
                <inline-svg class="svg-icon" :src="require('../assets/Icons/closedark.svg')" />
                <div class="items">
                    <div class="item search">
                        <input type="text" placeholder="Search">
                        <inline-svg class="search-icon" :src="require('../assets/Icons/searchlight.svg')" />
                    </div>
                    <div v-for="(coin,index) in pool" :key="index" @click="coin.choose=!coin.choose" class="item">
                        <div class="coin">
                            <img :src=coin.img />
                            <span>{{coin.name}}</span>
                        </div>
                        <div class="checkbox">
                            <inline-svg v-if="coin.choose" class="green-icon" :src="require('../assets/Icons/check.svg')" />
                        </div>
                    </div>
                </div>
                <a @click.prevent="" href="">Reset</a>
            </div>  
        </div>
    </transition>
</template>

<script>
    export default {
        name:'Liquidity',
        data(){
            return{
                pool:[
                    {
                        img:require('../assets/coins/BITCOIN.png'),
                        name:'BTC',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/LITE_COIN.png'),
                        name:'LTC',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/NEO.png'),
                        name:'NEO',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/DOGE_COIN.png'),
                        name:'DOGE',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/TETHER.png'),
                        name:'TTR',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/BITCOIN.png'),
                        name:'BTC',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/LITE_COIN.png'),
                        name:'LTC',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/NEO.png'),
                        name:'NEO',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/DOGE_COIN.png'),
                        name:'DOGE',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/TETHER.png'),
                        name:'TTR',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/BITCOIN.png'),
                        name:'BTC',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/LITE_COIN.png'),
                        name:'LTC',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/NEO.png'),
                        name:'NEO',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/DOGE_COIN.png'),
                        name:'DOGE',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/TETHER.png'),
                        name:'TTR',
                        choose:false,
                    },
                    {
                        img:require('../assets/coins/BITCOIN.png'),
                        name:'BTC',
                        choose:false,
                    },
                ],
            }
        },
        methods:{
            closeModale() {
            this.$emit('closeLiquidityModal' , false)
        },
        },
        created: function () {
            window.addEventListener("click",(event)=>{
            if(!event.target.closest('.modal,button,.setting-item')) {
              this.closeModale()
            }
            else  {
              return true
            }
            
          } )
  },
    }
</script>

<style lang="scss" scoped>
.checkbox{
    svg{
        width: 15px;
        height: 15px;
    }
    &:hover{
        opacity: 1;
    }
    opacity: .8;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    border: 1px solid var(--global-green);
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-icon{
    position: absolute;
    right: 20px;
    top: 20px;
}
input{
    border-radius: 12px;
    padding: 12px;
    background: var(--swap-background);
}
.item{
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px;
    &:hover{
        .coin{
            color: var(--global-green);
        }
    }
    .coin{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        column-gap: 5px;
        color: var(--title-color);
        img{
            height: 24px;
            width: 24px;
        }
    }
}
.items{
    height: 100%;
    overflow: scroll;
        position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100%;
}
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--modal-backdrop);
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    z-index: 110;
    overflow: scroll;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 430px;
    min-height: 500px;
    height: 85%;
    padding: 16px;
    background: var(--background-home);
    border-radius: 12px;
    box-shadow: 0px 0px 24px var(--shadow-color);
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
</style>