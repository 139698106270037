<template>
    <div class="banner">
        <div class="banner-container">
            <div class="explanations">
                <div class="title">
                    <h3>The set and forget yield maximizer</h3>
                    <p>an innovative ecosystem to use dapps gas-efficiently and gain extra yield. Onchain mini dap</p>
                </div>
                <div class="btns">
                    <button class="enter">Enter App</button>
                    <div class="dropdown-back" style="width:100%;max-width:111px">
                        <div class="dropdown-border">
                            <button class="more">More Info <inline-svg style="transform:rotate(-90deg)" class="svg-icon" :src="require('../../assets/Icons/lightDropdown.svg')" alt="" /></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swap-parent">
            <Swap @openConfirm="openConfirm" />
            </div>
        </div>
    </div>
</template>

<script>
import Swap from '../Swap/Swap.vue'
    export default {
        name:'Banner',
  components: { Swap },
    methods:{
        openConfirm(){
            this.$emit('openConfirm',true)
        }
    }
    }
</script>

<style lang="scss" scoped>
.enter{
    z-index: 5;
}
.swap-parent{
    width: 65%;
}
.btns{
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    column-gap: 16px;
    width: 100%;

}
.more{
    z-index: 5;
    width: 100%;
    background: none;
    border-radius: 12px;
}
.title{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}
.explanations{
    p{
        width: 50%;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    row-gap: 32px;
}
    .banner{
        z-index: 17;
        display: flex;
        width: 100%;
        // min-height: 709px;
        background-repeat: no-repeat !important;
        background: url('../../assets/aurora.png');
    }
    .banner-container{
        z-index: 12;
        display: flex;
        justify-content: space-around;
        width: 100%;
        align-items: flex-end;
        justify-items: end;
        flex-direction: row;
        padding:120px 64px;
    }
@media only screen and(max-width:1200px){
    .swap-parent{
        align-self: center;
    }
    .explanations{
        order: 1;
    }
    .swap-container{
        order: 2;
    }
    .banner-container{
        flex-direction: column;
        row-gap: 32px;
    }
    .title{
        text-align: center;
        align-items: center;
    }
    .btns{
        justify-content: center;
    }
}
@media only screen and(max-width:400px){
    .banner-container{
        padding: 120px 10px;
    }
}
@media only screen and(max-width:700px){
    .swap-parent{
        width: 100%;
    }
}
</style>