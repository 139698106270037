<template>
    <div class="market">
        <div class="title">
            <h1>Transaction History</h1>
        </div>
            <div class="table">
            <table>
                <thead>
                    <tr>
                        <td>Account</td>
                        <td class="remove">Height</td>
                        <td class="remove-two">Hash</td>
                        <td>In Token</td>
                        <td class="remove-two">in Amount</td>
                        <td class="remove">Out Token</td>
                        <td>Out Amount</td>
                    </tr>
                    <tr></tr>
                </thead>
                <tbody>
                    <tr class="items" v-for="(item,index) in tableitems" :key="index">
                        <td>Account</td>
                        <td class="remove">Height</td>
                        <td class="remove-two">Hash</td>
                        <td>In Token</td>
                        <td class="remove-two">in Amount</td>
                        <td class="remove">Out Token</td>
                        <td>Out Amount</td>
                    </tr>
                </tbody>
            </table>
            </div>
    </div>
</template>

<script>
    export default {
        name:'Market',
        data(){
            return{
                tableitems:['','','','','','','','','','']
            }
        }
    }
</script>

<style lang="scss" scoped>
.table{
    padding: 24px;
    width: 100%;
    height: 100%;
    overflow: scroll;
    display: flex;
    background: rgba(33, 51, 74, 0.27);
    backdrop-filter: blur(10px);
    box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.08);
    border-radius: 12px;
}
table{
    text-align: center;
    width: 100%;
    color: var(--title-color);
    font-size: clamp(12px,1vw,14px);
    border-spacing: 16px;
}
tbody{
    font-weight: 300;
    font-size: clamp(11px,1vw,13px);
    tr{
        transition:800ms;
        opacity: .5;
        &:hover{
            opacity: 1;
            cursor: pointer;
        }
    }
}
.market{
    background: url('../../assets/colorball.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    padding: 100px;
    height: 100%;
    width: 100%;
    display: flex;
    row-gap: 40px;
    flex-direction: column;
}
.title{
    color: var(--global-green);
    font-weight: 700;
    font-size: clamp(12px,1vw,14px);
}
@media only screen and(max-width:800px){
    .remove{
        display: none;
    }
    .table{
        padding: 10px;
    }
    .market{
        padding: 100px 20px;
    }
}
@media only screen and(max-width:650px){
    .remove-two{
        display: none;
    }
}
</style>