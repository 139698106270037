<template>
    <div class="banner">
        <div class="mission">
           <div class="text"> 
                <h3>Bibendum varius</h3>
                <p>Bibendum varius integer consequat quisque morbi amet, augue tincidunt. Lacus, gravida mauris, lacus nisl eget. Imperdiet tempor dictumst ac tellus nisl eget. Imperdiet tempor dictumst ac tellus elementum. Congue turpis amet massa, tempus rhoncus, vulputate in eleifend arcu. Sit sem sapien adipiscing et nunc auctor. Elit maecenas ac tellus fringilla lacinia est id squam.</p>
            </div> 
            <inline-svg class="img-animation" :src="require('../../assets/animationpics/aurora.svg')" alt="" />
        </div>
    </div>
</template>

<script>
    export default {
        name:'Aurora',
    }
</script>

<style lang="scss" scoped>
.banner{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 64px;
}
.mission{
    .img-animation{
        transition: 4s;
        animation-name: filter;
        animation-duration: 4s;
        animation-iteration-count :infinite;
        max-height: 300px;
        width: 40%;
        transition: 1s;
    }
    .text{
        width: 50%;
    }
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
@media only screen and(max-width:700px){
    .mission{
        flex-direction: column;
        row-gap: 30px;
        .img-animation{
            width: 100%;
            order: 1;
        }
        .text{
            order: 2;
            width: 100%;
        }
    }
}
@media only screen and(max-width:400px){
        .banner{
            padding: 10px;
        }
}
</style>