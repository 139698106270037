<template>
    <div class="footer">
        <div class="footer-container">
            <div class="info">
                <div class="info-container">
                    <img class="logo" src="../../assets/Icons/Logo.svg" alt="">
                <p>Bibendum varius integem. Congue turpis amet mass et cinia est id squam.</p>
                </div>
                <div class="social-icons">
                    <img src="../../assets/Icons/Facebook.svg" alt="">
                    <img src="../../assets/Icons/linkedin.svg" alt="">
                    <img src="../../assets/Icons/twitter.svg" alt="">
                    <img src="../../assets/Icons/instagram.svg" alt="">
                </div>
            </div>
            <div class="links">
                <div class="link bestarex">
                    <h4>Bestarex</h4>
                    <div class="items">
                    <a @click.prevent="$router.push({name:'Homepage'})" href="/">Home</a>
                    <a @click.prevent="$router.push({name:'Swap'})" href="/swap">Swap</a>
                    <a @click.prevent="" href="">Pools</a>
                    <a @click.prevent="" href="">Terms and conditions</a>
                    </div>
                </div>
                <div class="link aboutus">
                    <h4>About us</h4>
                    <div class="items">
                    <a @click.prevent="$router.push({name:'About'})" href="/about">About Bestarex</a>
                    <a @click.prevent="$router.push({name:'FAQ'})" href="faq">FAQ</a>
                    <a @click.prevent="" href="">Blog</a>
                    </div>
                </div>   
                <div class="link networks">
                    <h4>Networks</h4>
                    <div class="items">
                    <a @click.prevent="" href="">BNB</a>
                    <a @click.prevent="" href="">BSC</a>
                    </div>
                </div>
            </div>
            <div  class="img-container">
               <img src="../../assets/footerimg.svg" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Footer'
    }
</script>

<style lang="scss" scoped>
.footer-container::before{
    content: '';
    padding:300px;
    position: absolute;
    background-repeat: no-repeat;
    z-index: 5;
    bottom: -50px;
    right: 0px;
    opacity: .7;
    background: url('../../assets/footercolorball.png');
    background-size: 100%;
    background-position: center;
}
.footer-container::after{
    content: '';
    padding:200px 300px;
    position: absolute;
    z-index: 5;
    bottom: -40px;
    left: -100px;
    // opacity: .7;
    background: url('../../assets/greenball.png');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center;
}
.links{
    h4{
        font-family: 'poppins-bold' !important;
        font-weight: 900 !important;
        font-size: clamp(12px,1vw,14px);
        opacity: .7;
    }
    a{
         opacity: .7;
        font-weight: 100 !important;
        font-size: clamp(10px,1vw,12px) !important;
    }
    .items{
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    }
    .link{
        z-index: 10;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.social-icons{
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    img{
        max-width: 32px;
        max-height: 32px;
    }
}
.info{
    p{
         opacity: .7;
        font-weight: 100 !important;
        font-size: clamp(10px,1vw,12px) !important;

    }
    .info-container{
        display: flex;
        flex-direction: column;
        row-gap: 24px;
}
    padding-left: 50px;
    // background: url('../../assets/colorball.png');
    // backdrop-filter: blur(140px);
    // background-repeat: no-repeat;
    // background-position: top right;
    // background-size: 120%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    justify-content: flex-start;
    width: 15%;
}
.footer{
    // padding: 24px;
    // max-height: 340px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 10;
    background: var(--background-home);
    background-size: cover;
    overflow: hidden;
    // box-shadow: 0px 0px 24px rgba(255, 255, 255, 0.2);
}
.footer-container{
    backdrop-filter: blur(10px);
    width: 100%;
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.img-container{
    // background: url('../../assets/colorball.png');
    // backdrop-filter: blur(140px);
    // background-repeat: no-repeat;
    // background-position: top;
    // background-size: 120%;
    img{
        width: 100%;
        height: 100%;
    }
        max-width: 379px;
        height: 295px;
}
@media only screen and(max-width:1100px){
    .footer-container::after{
        display: none;
    }
    .footer-container::before{
        display: none;
    }
    .img-container{
        // img{
            display: none !important;

        // }

    }
    .info-container{
        flex-direction: row !important;
        column-gap: 10px;
    }
    .footer-container{
        justify-content: space-between;
    }
    .info{
        width: 40%;
    }
}
@media only screen and(max-width:600px){
    .footer{
        box-shadow: 0px 0px 24px rgba(255, 255, 255, 0.2) !important;
        }
    .footer-container{
        flex-direction: column;
        row-gap: 24px;
        .info{
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .links{
            width: 100%;
        }
    }
}
</style>