import Vue from 'vue'
import App from './App.vue'
import router from './Lib/Router';
import InlineSvg from 'vue-inline-svg';
import './Styles/Globalstyles.scss';
import './Styles/Animations.css';
import './Lib/State';

Vue.config.productionTip = false;
Vue.component('inline-svg', InlineSvg);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
