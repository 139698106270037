<template>
    <div class="faq">
        <div class="faq-background">
            <div class="items-columns">
                <div class="items-column">
                    <div v-for="(ask,index) in questions" :key="index" class="item">
                        <h4>Why Are you like this ?</h4>
                        <p>Bibendum varius integer consequat quisque morbi amet, augue tincidunt. Lacus, gravida mauris, lacus nisl eget. Imperdiet tempor dictumst ac tellus elementum. Congue turpis amet massa, tempus rhoncus, vulputate in eleifend arcu. Sit sem sapien adipiscing et nunc auctor. Elit maecenas ac tellus fringilla lacinia est id squam.</p>
                        <button class="more">
                            <span>more</span>
                            <inline-svg class="svg-icon" :src="require('../../assets/Icons/lightDropdown.svg')" alt="" />
                        </button>
                    </div>
                </div>
                <div class="items-column">
                    <div v-for="(ask,index) in questions" :key="index" class="item">
                        <h4>Why Are you like this ?</h4>
                        <p>Bibendum varius integer consequat quisque morbi amet, augue tincidunt. Lacus, gravida mauris, lacus nisl eget. Imperdiet tempor dictumst ac tellus elementum. Congue turpis amet massa, tempus rhoncus, vulputate in eleifend arcu. Sit sem sapien adipiscing et nunc auctor. Elit maecenas ac tellus fringilla lacinia est id squam.</p>
                        <button class="more">
                            <span>more</span>
                            <inline-svg class="svg-icon" :src="require('../../assets/Icons/lightDropdown.svg')" alt="" />
                        </button>
                    </div>
                </div>
            </div>
            <div class="title">
                <img src="../../assets/Icons/questionmark.svg" alt="">
                <h1>FAQ</h1>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Faq',
        data(){
            return{
                questions:['','','',]
            }
        },
        mounted(){
            this.state.theme='dark'
        }
    }
</script>

<style lang="scss" scoped>
.more{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    align-self: flex-end;
    justify-self: end;
    background: none;
    color: var(--title-color);
    &:hover{
        color: var(--global-green);
        .svg-icon{

            fill: var(--global-green);
        }
    }
}
.items-column{
    width: 80%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    .item{
        width: 95%;
        display: flex;
        border-radius: 12px;
        flex-direction: column;
        row-gap: 8px;
        padding: 16px;
        background: rgba(33, 51, 74, 0.27);
        backdrop-filter: blur(10px);
        box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.08);
    }
}
.items-columns{
    height: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.title{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 20%;
    color: var(--skin-color);
    font-weight: 700;
    font-size: clamp(36px,3vw,48px);
}
.faq-background{
    background: url('../../assets/ribbon.svg');
    background-repeat: no-repeat;
    background-size: 40%;
    background-position: right;
    height: 100%;
    width: 100%;
    padding: 100px 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.faq{
    background: url('../../assets/colorball.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    height: 100%;
    width: 100%;
}
@media only screen and(max-width:1100px){
        .title{
            display: none;
        }
        .items-column{
            width: 100%;
        }
}
@media only screen and(max-width:600px){
        .items-columns{
            flex-direction: column;
        }
        .faq-background{
            padding: 100px 10px;
        }
}
</style>