<template>
    <div class="bigb">
        <h3>Bestarex Networks</h3>
        <div class="animation">
            <inline-svg class="b-icon" :src="require('../../assets/animationpics/B.svg')" />
            <inline-svg class="lines-icon" :src="require('../../assets/animationpics/lines.svg')" />
        </div>
    </div>
</template>

<script>
    export default {
        name:'Bigb',
    }
</script>

<style lang="scss" scoped>
.lines-icon{
    position: absolute;
    // left:14%;
    top: 0px;
    width: 100%;
    z-index: 0;
}
.b-icon{
    z-index: 2;
    width: 100% ;
}
.animation{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    height: 610px;
    // &::before{
    //     background: url('../../assets/animationpics/lines.svg');
    //     background-repeat: no-repeat;
    //     background-size: 60%;
    //     background-position: center;
    //     content: '';
    //     min-height: 610px;
    //     width: 100%;
    //     bottom: 0;
    // }
}
.bigb{
    row-gap: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 64px;
}
@media only screen and(max-width:1100px){
// .b-icon{
//         width: 200px;
//         height:300px;
//         bottom: 30%;
// }
.animation{
    &::before{
        background-size: 90%;
    }
}
@media only screen and(max-width:1000px){
// .b-icon{
//         width: 200px;
//         height:300px;
//         bottom: 30%;
// }
.animation{
    &::before{
        background-size: 100%;
    }
}
}


}
@media only screen and(max-width:800px){
.b-icon{
    
        width: 50%;
}
.animation{
    &::before{
        background-size: 100%;
    }
}
}
@media only screen and(max-width:600px){
.lines-icon{
    
        top: -200px;
}
.animation{
    height: unset;

}
}
// @media only screen and(max-width:450px){
// .b-icon{
    
//         width: 130px;
//         height:200px;
//         bottom: 33%;
// }
// }
// @media only screen and(max-width:380px){
// .b-icon{
    
//         width: 100px;
// }
// .lines-icon{
//     top: -20px;
// }
// }
</style>