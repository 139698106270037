import Vue from 'vue';
import VueRouter from 'vue-router';
import Mainswap from '../components/Swap/Mainswap.vue';
import Homepage from '../components/Homepage/Homepage.vue'
import Market from '../components/Market/Market.vue';
import Faq from '../components/FAQ/Faq.vue';
import About from '../components/About/About.vue';
Vue.use(VueRouter);
const routes =[
    {
        path :'/',
        name :'Homepage',
        component: Homepage
    },
    {
        path :'/swap',
        name :'Swap',
        component: Mainswap
    },
    {
        path :'/swapsuggestion',
        name :'Swapsuggestion',
        component: Mainswap
    },
    {
        path :'/market',
        name :'Market',
        component: Market
    },
    {
        path :'/Faq',
        name :'FAQ',
        component: Faq
    },
    {
        path :'/about',
        name :'About',
        component: About
    },
];
const router = new VueRouter({
    mode : 'history',
    base : process.env.BASE-URL,
    routes
})

export default router