<template>
    <div :class="{'home-back':$route.name=='Homepage'}" class="swap container">
        <div class="swap-container">
            <div class="header">
                <span class="title">{{title}}</span>
                <inline-svg class="svg-icon" @click="changeSwap" v-if="setting==false" :src="require('../../assets/Icons/darksetting.svg')" alt="" />
                <inline-svg class="svg-icon" @click="changeSwap" v-if="setting==true" :src="require('../../assets/Icons/lightDropdown.svg')" alt="" />
            </div>
            <transition name="fade">
                <div :class="{'absolute':setting}" v-if="setting==false" class="body">
                    <div :style="{ top: fromtop + '%'}" class="exchange">
                        <div class="states">
                            <h4>{{top}}</h4>
                            <div class="combine-text">
                            <p>balance</p>
                                <span class="price">${{toman.price}}</span> 
                            </div>
                        </div>
                        <div class="states">
                            <div @click.self="topdropdownclick=!topdropdownclick" class="dropdown">
                                <div @click="topdropdownclick=!topdropdownclick" class="drop-items">
                                    <div class="coin-chooser">
                                        <img :src=coins[0].img alt="">
                                        <span>{{coins[0].name}}</span>
                                    </div>
                                    <div v-if="top=='From'"  class="max">
                                        <span class="max">MAX</span>
                                    </div>
                                </div>
                                <inline-svg @click="topdropdownclick=!topdropdownclick" :class="{'dropdow-click':topdropdownclick}" :src="require('../../assets/Icons/lightDropdown.svg')" class="svg-icon"/>
                                <div v-if="topdropdownclick==true" class="choose">
                                    <div class="search-bar">
                                        <input type="text" placeholder="Search">
                                        <inline-svg :src="require('../../assets/Icons/searchlight.svg')" class="search-icon" alt="" />
                                    </div>
                                    <div class="coins" v-for="(coin,index) in fakecoins" :key="index">
                                        <img class="coin-img" :src=coin.img alt="">
                                        <span>{{coin.name}}</span>
                                    </div>
                                </div>
                            </div>
                            <p class="coinprice">{{toman.coinprice}}</p>
                        </div>
                        <div class="states">
                            <h4 style="opacity: .6;">{{coins[0].name}}</h4>
                                <p>${{toman.price}}</p> 
                        </div>
                    </div>
                    <inline-svg :class="{'rotate':fromtop!==0}" @click="changeDeal" class="change svg-icon" :src="require('../../assets/Icons/Chage.svg')" alt="" />
                    <div :style="{ bottom: frombottom + '%'}" class="exchange">
                        <div class="states">
                            <h4>{{bottom}}</h4>
                            <div class="combine-text">
                            <p>balance</p>
                                <span class="price">${{toman.price}}</span> 
                            </div>
                        </div>
                        <div class="states">
                            <div @click.self="bottomdropdownclick=!bottomdropdownclick" class="dropdown">
                                <div @click="bottomdropdownclick=!bottomdropdownclick" class="drop-items">
                                    <div class="coin-chooser">
                                        <img :src=coins[4].img alt="">
                                        <span>{{coins[4].name}}</span>
                                    </div>
                                    <div v-if="bottom=='From'" class="max">
                                        <span class="max">MAX</span>
                                    </div>
                                </div>
                                <inline-svg @click="bottomdropdownclick=!bottomdropdownclick" :class="{'dropdow-click':bottomdropdownclick}" :src="require('../../assets/Icons/lightDropdown.svg')" class="svg-icon"/>
                                <div v-if="bottomdropdownclick==true" class="choose">
                                    <div class="search-bar">
                                        <input type="text" placeholder="Search">
                                        <inline-svg :src="require('../../assets/Icons/searchlight.svg')" class="search-icon" alt="" />
                                    </div>
                                    <div class="coins" v-for="(coin,index) in fakecoins" :key="index">
                                        <img class="coin-img" :src=coin.img alt="">
                                        <span>{{coin.name}}</span>
                                    </div>
                                </div>
                            </div>
                            <p class="coinprice">{{toman.coinprice}}</p>
                        </div>
                        <div class="states">
                            <h4 style="opacity: .6;">{{coins[0].name}}</h4>
                                <p>${{toman.price}}</p> 
                        </div>
                    </div>
                    <div class="swap-btn">
                     <button v-if="!state.username" @click="connectWallet" class="swap-btn">Connect Wallet</button>
                     <button v-else @click="swap" class="swap-btn">swap</button>
                    </div>
                </div>
            </transition>
            <transition name="fade">
            <Setting v-if="setting==true" />
            </transition>
        </div>
    </div>
</template>

<script>
import Data from '../../Lib/Fakedata';
import Setting from './Setting.vue';
    export default {
  components: { Setting },
        name:'Swap',
       data(){
           return{
               title:'swap',
               setting:false,
               fakecoins:[
                   {
                       img:require('../../assets/coins/BINANCE_COIN_BSC.png'),
                        name:'BSC Mainnet'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
                   {
                       img:require('../../assets/coins/ETHEREUM.png'),
                        name:'Ethereum'
                   },
               ],
               topdropdownclick:false,
               bottomdropdownclick:false,
               frombottom:15,
                top:'From',
        bottom:'To',
        fromtop:0,
        coins:Data,
        toman:{
                img:require('../../assets/coins/BITCOIN.png'),
                price:8975,
                coinprice:3548,
                finalprice:4634,
            }
           }
       },
       methods:{
            connectWallet(){
                this.state.walletmodal=true;
            },
           changeDeal(){
               if(this.top=='To'){
                   this.top='From'
               }else{
                   this.top='To'
               }
               if(this.bottom=='To'){
                   this.bottom='From'
               }else{
                   this.bottom='To'
               }
               if(this.fromtop==0){
                   this.fromtop=50
                   this.frombottom=65
               }else{
                   this.frombottom=15
                   this.fromtop=0
               }
               
           },
           swap(){
               this.$emit('openConfirm',true);
           },
           changeSwap(){
               if(this.setting==false){
                   this.setting=true
                   this.title='Setting'
               }else{
                   this.setting=false
                   this.title='swap'
               }
           }
       },
        created: function () {
            window.addEventListener("click",(event)=>{
            if(!event.target.closest('.dropdown')) {
              this.topdropdownclick=false;
               this.bottomdropdownclick=false;
            }
            else  {
              return true
            }
            
          } )
  },
    }
</script>

<style lang="scss" scoped>
.swap-btn{
    z-index: 12;
}
.absolute{
    position: absolute !important;
}
.choose{
    z-index: 21;
    left: 0;
    width: 100%;
    background: var(--background-home);
    box-shadow: 0px 0px 24px rgba(255, 255, 255, 0.2);
    border-radius: 12px;
    color: var(--title-color);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    max-height: 240px;
    overflow: scroll;
    .coins{
            &:hover{
        color: var(--global-green);
    }
        transition: 1s;
        display: flex;
        flex-direction: row;
        padding: 8px;
        column-gap: 5px;
        font-weight: 500;
        font-size: clamp(11px,1vw,14px);
        
    }
    .search-bar{
        font-weight: 300;
        align-items: center;
        font-size: clamp(10px,1vw,13px);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
    svg{
        position: absolute;
        right: 8px;
        top: 8px;
        // background: var(--svg-color);
    }
    
    }
}
.coin-chooser{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
}
.max{
    background: var(--global-green);
    border-radius: 6px;
    padding: 0 2px;
    display: flex;
    justify-content: center;
    align-items: center;
        transition: 800ms;
    span{
        color: var(--ingreen-text) !important;
        font-weight: 100 !important;
        font-size: clamp(8px,1vw,10px) !important;
    }
}
.dropdown{
    .drop-items{
        display: flex;
        flex-direction: row;
        column-gap: 12px;
        align-items: center;
        span{
            font-weight: 700;
            font-size: clamp(14px,1vw,16px);
            color:var(--title-color)
        }
        img{
            height: 34px;
            width: 34px;
        }
    }
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    width: 53%;
    padding: 12px;
    flex-direction: row;
    justify-content: space-between;
    background: var(--swap-items-background);
border-radius: 8px;
}
.coinprice{
    opacity: .3;
    transition: 1s;
    font-size: clamp(20px,2vw,24px);
}
.price{
    color: var(--global-green);
    opacity: 2 !important;
}
.exchange{
    transition: 1s;
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 8px;
        background: var(--swap-background);
    padding: 16px;
        border-radius: 12px;
        &:hover{
            .coinprice{
                opacity: .6;
            }
        }
        &:focus-within{
            .coinprice{
                opacity: 1;
            }
        }
    .states{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
    }
}
.exchange::before{
    // content: "";
    background: linear-gradient(223.32deg, #590C51 -6.08%, rgba(115, 124, 193, 0) 49.43%, #157A75 109.02%);
    position: absolute;
    z-index: -1;
    width: 101%;
    height: 101%;
    top: 0;
    left: -2px;
    border-radius: 12px;
}
.title{
    transition: 1s;
    font-weight: 700;
    font-size: clamp(18px,2vw,22px);
    color: var(--title-color);
}
.change{
    fill: var(--global-green);
    transition: none !important;
    max-width: 32px;
    max-height: 32px;
    cursor: pointer;
    align-self: center;
    position: absolute;
    top: 38%;
}
.header{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    transition: 1s;
}
.swap{
    box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.08);
    align-self: center;
    width:100%;
    border-radius: 8px;
    backdrop-filter: blur(200px);
    background: var(--swap-blur);
        min-height: 540px;
}
.body{
        display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: relative;
    button{
        position: absolute;
        bottom: 0;
    }
}
.swap-container{
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 100%;
    button{
        width: 100%;
    }
}
@media only screen and(max-width:750px){
    .swap{
        width: 100%;
    }
}
@media only screen and(max-width:500px){
 .states:nth-child(2){
        // flex-direction: column;
        // row-gap: 10px;
        .dropdown{
            width: 70%;
        }
 }
 .swap{
     padding: 10px 24px !important;
 }
     .exchange{
         row-gap: 5px;
     } 
}
@supports not (backdrop-filter: blur()){
    .home-back{
        background:url('../../assets/homeswap-back.png');
        background-repeat: no-repeat;
        background-size: 100%;
    }
    .swap-parent{
        background: unset;
    }
}
</style>