<template>
    <div class="about">
        <div class="colorball"></div>
        <div class="foundation">
            <div class="text">
                <h4>Bestarex Foundation</h4>
                <p>Bibendum varius integer consequat quisque morbi amet, augue tincidunt. Lacus, gravida mauris, lacus nisl eget. Imperdiet tempor dictumst ac tellus elementum. Congue turpis amet massa, tempus rhoncus, vulputate in eleifend arcu. Sit sem sapien adipiscing et nunc auctor. Elit maecenas ac tellus fringilla lacinia est id squam.Bibendum varius integer consequat quisque morbi amet, augue tincidunt. Lacus, gravid</p>
            </div>
            <div class="image">
                <img class="laptop" src="../../assets/animationpics/Laptop.svg" alt="">
                <div class="coins">
                    <img src="../../assets/animationpics/coin-icons/BITCOIN.png" class="bitcoin" alt="">
                    <img src="../../assets/animationpics/coin-icons/BINANCE.png" class="binance" alt="">
                    <img src="../../assets/animationpics/coin-icons/CARDANO.png" class="cardano" alt="">
                    <img src="../../assets/animationpics/coin-icons/ETH.png" class="eth" alt="">
                    <img src="../../assets/animationpics/coin-icons/LTC.png" class="ltc" alt="">
                    <img src="../../assets/animationpics/coin-icons/STELLAR.png" class="stellar" alt="">
                </div>
            </div>
        </div>
        <div class="advantages">
            <h3>advantage</h3>
            <div class="advantages-container">
                <div class="advantage-border">
                    <div class="advantage-shadow">
                        <div class="advantage">
                            <img src="../../assets/nolocation.svg" alt="">
                            <div class="text">
                                <h4>No location will be saved</h4>
                                <p>Bibendum varius integer consequat m. Congue turpis amet Bibendum varius integer consequat m. Congue turpis amet </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="advantage-border">
                    <div class="advantage-shadow">
                        <div class="advantage">
                            <img src="../../assets/safe.svg" alt="">
                            <div class="text">
                                <h4>Easy and Safe</h4>
                                <p>Bibendum varius integer consequat m. Congue turpis amet Bibendum varius integer consequat m. Congue turpis amet </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="advantage-border last">
                    <div class="advantage-shadow">
                        <div class="advantage">
                            <img src="../../assets/cnwallet.svg" alt="">
                            <div class="text">
                                <h4>Connecting to your wallet</h4>
                                <p>Bibendum varius integer consequat m. Congue turpis amet Bibendum varius integer consequat m. Congue turpis amet </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mission">
            <div class="text">
                <h3>Mission</h3>
                <p>Bibendum varius integer consequat quisque morbi amet, augue tincidunt. Lacus, gravida mauris, lacus nisl eget. Imperdiet tempor dictumst ac tellus nisl eget. Imperdiet tempor dictumst ac tellus elementum. Congue turpis amet massa, tempus rhoncus, vulputate in eleifend arcu. Sit sem sapien adipiscing et nunc auctor. Elit maecenas ac tellus fringilla lacinia est id squam.</p>
            </div>
            <img class="img-animation" src="../../assets/goal.svg" alt="">
        </div>
        <div class="statistics-container">
            <h3>statistics</h3>
            <div class="statistics">
                <div class="statistic-border">
                    <div class="statistic-shadow">
                        <div class="statistic">
                            <h3>{{pairs}}</h3>
                            <span>Total pairs</span>
                        </div>
                    </div>
                </div>
                <div class="statistic-border">
                    <div class="statistic-shadow">
                        <div class="statistic">
                            <h3>${{pairs}}</h3>
                            <span>Total Liquidity</span>
                        </div>
                    </div>
                </div>
                <div class="statistic-border">
                    <div class="statistic-shadow">
                        <div class="statistic">
                            <h3>${{pairs}}</h3>
                            <span>Total Volume</span>
                        </div>
                    </div>
                </div>
                <div class="statistic-border">
                    <div class="statistic-shadow">
                        <div class="statistic">
                            <h3>{{pairs}}</h3>
                            <span>Users</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'About',
        data(){
            return{
                pairs:7594,
            }
        }
    }
</script>

<style lang="scss" scoped>
.statistics-container{
    width: 100%;
    padding: 10px 64px;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
}
.statistics{
    .statistic-border{
        &:hover{
            box-shadow: 8px -8px 48px 0px rgba(38, 224, 214, 0.24);
            .statistic-shadow{
                box-shadow: -8px 8px 48px 0px rgba(250, 36, 124, 0.24);

            }
            .statistic{
                box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 1);
                
            }
        }
        // overflow: hidden;
        background: linear-gradient(223.32deg, #26E0D6 -6.08%, rgba(115, 124, 193, 0) 49.43%, #BE1AAC 109.02%);
        border-radius: 12px;
        min-width: 163px;
        width: 23%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 800ms;
    .statistic-shadow{
        transition: 800ms;
        width: 99%;
        height: 100%;
        transform: scaleY(.99);
    }
    }
    .statistic{
        border-radius: 12px;
        background: var(--background-home);
        padding: 24px;
        row-gap: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    flex-wrap: wrap;
    row-gap: 24px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    column-gap:24px ;
    color: var(--title-color);
}
.mission{
    .img-animation{
        animation-name: goal;
        animation-duration: 4s;
        animation-iteration-count :infinite;
        position: absolute;
        right: 50px;
    }
    .text{
        width: 60%;
    }
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 10px 64px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.advantage-border{
    width: 45%;
    background: linear-gradient(223.32deg, #26E0D6 -6.08%, rgba(115, 124, 193, 0) 49.43%, #BE1AAC 109.02%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    box-shadow: 8px -8px 48px 0px rgba(38, 224, 214, 0.24);

}
.advantage-shadow{
    width: 99.5%;
    height: 99%;
    margin-bottom: .2%;
    box-shadow: -8px 8px 48px 0px rgba(250, 36, 124, 0.24);

}
.advantage{
    box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 1);
    background: var(--background-home);
    padding: 24px;
    display: flex;
    flex-direction: row;
    height: 100%;
    column-gap: 16px;
    width: 100%;
    border-radius: 12px;
}
.text{
    overflow: scroll;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.advantages{
    .advantages-container{
        justify-content: center;
        column-gap: 24px;
        row-gap: 32px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-self: center;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 80px;
    // align-items: flex-start;
    width: 100%;
    padding: 10px 64px;
    h4{
        font-weight: 700;
        font-size: clamp(20px,2vw,24px);
    }
}
.coins{
    img{
        position: absolute;
    }
}
.image{
    position: relative;
    .bitcoin{
        top: 50px;
        left: 70px;
        transform: scale(.75);
        animation-name: bitcoin;
        animation-duration: 2s;
        animation-iteration-count :infinite;
    }
    .binance{
        top: 80px;
        left:210px;
        transform: scale(.45);
        animation-name: binance;
        animation-duration: 2s;
        animation-iteration-count :infinite;
    }
    .cardano{
        top: 220px;
        left:280px;
        transform: scale(.4);
        animation-name: cardano;
        animation-duration: 2s;
        animation-iteration-count :infinite;
    }
    .eth{
        top: 50px;
        left:300px;
        transform: scale(.4);
        animation-name: eth;
        animation-duration: 2s;
        animation-iteration-count :infinite;
    }
    .ltc{
        top: 40px;
        left:430px;
        transform: scale(.4);
        animation-name: ltc;
        animation-duration: 2s;
        animation-iteration-count :infinite;
    }
    .stellar{
        top: 200px;
        left:350px;
        transform: scale(.4);
        animation-name: stellar;
        animation-duration: 2s;
        animation-iteration-count :infinite;
    }
}
.foundation{
    // overflow: hidden;
    display: flex;
    flex-direction: row;
    padding: 10px 64px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .text{
        h4{
            font-weight: 700;
            font-size: clamp(20px,3vw,24px);
        }
        width: 45%;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
    }
}
.colorball{
background: url('../../assets/colorball.png');
    background-position: 200% 0;
    background-repeat: no-repeat;
    background-size: 100%;
    position: absolute;
    padding: 400px 600px;
    right: -200px;
    top: 0;
}
.about{
    overflow: hidden;
    padding: 100px 10px;
    display: flex;
    flex-direction: column;
    row-gap: 150px;
    // height: 100vh;
    position: relative;
    background: url('../../assets/ribbon-about.png');
    background-repeat: no-repeat;
    background-size: 100% 70%;
    background-position: bottom;
}
@media only screen and(max-width:1200px){
    .foundation,.advantages,.mission{
        flex-direction: column;
    }
    .foundation{
        .image{
            order: 1;
            .laptop{
                width: 100%;
            }
        }
        .text{
            order: 2;
            width: 100%;
        }
        padding: 10px;
    }
.advantages{
    padding: 0;
}
.advantages-container{
    flex-direction: column;
    align-items: center;
    .advantage-border{
        width:100% ;
    }
    .advantage{
        padding: 8px;
    }
}
.mission{
    padding: 10px;
    flex-direction: column;
    img{
        display: none;
    }
    .text{
        width: 100%;
    }
}
}
@media only screen and(max-width:700px){
    .coins{
        display: none;
    }
}
@media only screen and(max-width:500px){
    .statistics{
        flex-direction: column;
        .statistic-border{
            width: 100%;
        }
    }
}
</style>