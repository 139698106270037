<template>
    <div class="slider">
        <div class="border">
        <hooper class="carousel" :infiniteScroll="false" :progress="false" :autoPlay="false" :playSpeed="400" :ltr="true"  :settings="hooperSettings">
        <slide  v-for="(post,index) in news" :key="index">
                <div class="slide-container">
                    <div class="text">
                    <h3>Bibendum varius</h3>
                    <p>Bibendum varius integer consequat quisque morbi amet, augue 
                        tincidunt. Lacus, gravida mauris, lacus nisl eget.
                         Imperdiet tempor dictumst ac tellus elementum. 
                         Congue turpis amet massa, tempus rhoncus,
                          vulputate in eleifend arcu. Sit sem sapien adipiscing et nunc auctor. 
                          Elit maecenas ac tellus fringilla lacinia est id squam.</p>
                    </div>
                    <img src="../../assets/Gold.svg" alt="">
                </div>
         </slide>
        <hooper-pagination class="item-number" slot="hooper-addons"></hooper-pagination>
        </hooper>  
        </div>
    </div>  
</template>

<script>

import { Hooper, Slide ,Pagination as HooperPagination} from 'hooper';
import 'hooper/dist/hooper.css';
export default {
  name: 'Slider',
  components:{
      Hooper,Slide,HooperPagination
  },
  data(){
      return{
        hooperSettings: {
        itemsToShow: 1,
        centerMode:false, 
        },
        news:['','','','',],
        
      }
  }
}
</script>

<style lang="scss" scoped>
.text{
    display: flex;
    width: 60%;
    flex-direction: column;
    justify-content: center;
    row-gap: 8px;
    height: 100%;
    h4{
        font-weight: 700;
        font-size: clamp(18px,2vw,22px);
    }
    p{
        font-weight: 300;
        font-size: clamp(10px,2vw,14px);
        overflow: scroll;
    }
}
.slide-container{
    width: 100%;
    overflow: scroll;
    padding: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    background: url('../../assets/slider-ribbon.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    img{
        height: 100%;
    }
}
.border{
    background: linear-gradient(223.32deg, #590C51 -6.08%, rgba(115, 124, 193, 0) 49.43%, #157A75 109.02%);
    width: 100%;border-radius: 12px;
}
.carousel{
    overflow: scroll;
    width: 100%;
    transform: scale(.999,.99);
    background: var(--background-home);
    border-radius: 12px;
}
.slider{
    z-index: 10;
    // background: red;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding: 100px 64px;
}
@media only screen and(max-width:1000px){
    .slide-container{
        img{
            display: none;
        }
        .text{
            width: 100%;
        }
    }
}
@media only screen and (min-width:1024px){
    .border{
        min-height: 320px;
    }
    .carousel{
        min-height: 320px;
    }
}
</style>