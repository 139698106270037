<template>
    <transition name="fade">
        <div class="modal-backdrop" >
          <div class="modal">
                <div class="content">
                    <div class="header">
                        <span class="title">Confirm swap</span>
                        <inline-svg class="swap-icon" @click="closeModale" :src="require('../../assets/Icons/closelight.svg')" alt="" />
                    </div>
                    <div class="coins">
                        <div class="coin-container">
                            <div class="img-container">
                            <img :src=coins[0].img alt="">
                            <span>0.4</span>
                            </div>
                            <span>{{coins[0].name}}</span>
                        </div>
                        <inline-svg class="change-icon green-icon" :src="require('../../assets/Icons/Chage.svg')" alt="" />
                        <div class="coin-container">
                            <div class="img-container">
                            <img :src=coins[4].img alt="">
                            <span>0.4</span>
                            </div>
                            <span>{{coins[4].name}}</span>
                        </div>
                    </div>
                    <div class="explain">
                        <p>Output is Stimated.You Will receive at least 
                        <span class="fee">{{0.4748}} BTC </span> the transaction will revert.</p>
                    </div>
                    <div class="status">
                        <div class="data">
                            <span>Price</span>
                            <div class="numbers">
                                <span class="num">{{coins[0].remain}}</span>
                                <span class="name">{{coins[0].name}}/{{coins[4].name}}</span>
                            </div>
                        </div>
                        <div class="data">
                            <span>Price Impact ?</span>
                            <div class="numbers">
                                <span class="num">{{coins[0].remain}}</span>
                                <span class="name">{{coins[0].name}}</span>
                            </div>
                        </div>
                        <div class="data">
                            <span>liquidity Provider fee ?</span>
                            <div class="numbers">
                                <span class="num">{{coins[0].useable}}</span>
                                <span class="name">{{coins[0].name}}</span>
                            </div>
                        </div>
                    </div>
                    <button @click="confirm">Confirm</button>
                </div>
          </div>
        </div>
    </transition>
</template>

<script>
import Data from '../../Lib/Fakedata';
export default {
  name: 'Confirmmodal',
  data() {
      return {
        coins:Data,
      }
  },
  methods : {
      closeModale() {
        this.$emit('closeConfirmModal' , false)
      },
      confirm(){
          if(this.$route.name!== 'Swapsuggestion'){
              this.$router.push({name: 'Swapsuggestion'})
              this.closeModale();
          } else {
              this.$router.push({name: 'Swap'})
              this.closeModale();
          }
      }
  },
  mounted (){
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest('.modal,button')) {
              this.closeModale()
            }
            else  {
              return true
            }
            
          } )
  },
  }
</script>

<style lang="scss" scoped>
button{
    margin-top: 30px;
}
.status{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    color: var(--title-disable-color);
    .data{
        .numbers{
            .num{
                color: var(--title-color) !important;
            }
            display: flex;
            flex-direction: row;
            column-gap: 5px;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.fee{
    font-weight: 700;
    font-size: clamp(14px,2vw,18px);
}
.img-container{
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    align-items: center;
}
.coins{
    display: flex;
    flex-direction: column;
}
.change-icon{
    align-self: center;
    justify-self: center;
}
.coin-container{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    img{
        width: 40px;
        height: 40px;
    }
    span{
        color: var(--title-disable-color);
    }
}
.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    transition: 1s;
    .title{
        color: var(--global-green);
        span{
            font-weight: 700 !important;
            font-size: clamp(18px,2vw,22px) !important;
        }
    }
}
.swap-icon{
    cursor: pointer;
    transform: rotate(-90deg);
    max-width: 14px;
    max-height: 14px;
}
.content{
    width: 100%;
    min-height: 160px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    padding: 24px;
    background: url('../../assets/colorball.png') ;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 12px;
}
    .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--modal-backdrop);
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    z-index: 110;
  }
  .modal {
    display: flex;
    flex-grow: 1;
    max-width: 430px;
    min-height: 160px;
    background: var(--background-home);
    border-radius: 12px;
    box-shadow: 0px 0px 24px var(--shadow-color);
    position: relative;
    // margin: 24px;
    box-sizing: border-box;
    // padding: 8px;
    // padding-bottom:24px ;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>