import Vue from 'vue';

Vue.use(require('vue-cookies'))

const that = Vue.prototype

const state = Vue.observable({
    username:'',
    theme:'dark',
    walletmodal:false,
    liquiditymodal:false,
    loading: false,
})

that.state = state
export default state