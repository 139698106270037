<template>
   <div class="statistics-container">
            <h3>statistics</h3>
            <div class="statistics">
                <div class="statistic-border">
                    <div class="statistic-shadow">
                        <div class="statistic">
                            <h3>{{pairs}}</h3>
                            <span>Total pairs</span>
                        </div>
                    </div>
                </div>
                <div class="statistic-border">
                    <div class="statistic-shadow">
                        <div class="statistic">
                            <h3>${{pairs}}</h3>
                            <span>Total Liquidity</span>
                        </div>
                    </div>
                </div>
                <div class="statistic-border">
                    <div class="statistic-shadow">
                        <div class="statistic">
                            <h3>${{pairs}}</h3>
                            <span>Total Volume</span>
                        </div>
                    </div>
                </div>
                <div class="statistic-border">
                    <div class="statistic-shadow">
                        <div class="statistic">
                            <h3>{{pairs}}</h3>
                            <span>Users</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        name:'Statistics',
        data(){
            return{
                pairs:7594,
            }
        }
    }
</script>

<style lang="scss" scoped>
.statistics-container{
    width: 100%;
    padding: 10px 64px;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    z-index: 5;
}
.statistics{
    .statistic-border{
        &:hover{
            box-shadow: 8px -8px 48px 0px rgba(38, 224, 214, 0.24);
            .statistic-shadow{
                box-shadow: -8px 8px 48px 0px rgba(250, 36, 124, 0.24);

            }
            .statistic{
                box-shadow: 8px 8px 8px 0px rgba(0, 0, 0, 1);
                
            }
        }
        // overflow: hidden;
        background: linear-gradient(223.32deg, #26E0D6 -6.08%, rgba(115, 124, 193, 0) 49.43%, #BE1AAC 109.02%);
        border-radius: 12px;
        min-width: 163px;
        width: 23%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 800ms;
    .statistic-shadow{
        transition: 800ms;
        width: 99%;
        height: 100%;
        transform: scaleY(.99);
    }
    }
    .statistic{
        border-radius: 12px;
        background: var(--background-home);
        padding: 24px;
        row-gap: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    flex-wrap: wrap;
    row-gap: 24px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    column-gap:24px ;
    color: var(--title-color);
}
@media only screen and(max-width:500px){
    .statistics{
        align-items: center;
        justify-content: center;
    }
}
</style>