<template>
    <div class="coins">
        <img src="../../assets/wallet-icons/image 11.svg" alt="">
        <img src="../../assets/wallet-icons/image 12.svg" alt="">
        <img src="../../assets/wallet-icons/image 13.svg" alt="">
        <img src="../../assets/wallet-icons/image 14.svg" alt="">
        <img src="../../assets/wallet-icons/image 15.svg" alt="">
        <img src="../../assets/wallet-icons/image 16.svg" alt="">
        <img src="../../assets/wallet-icons/image 17.svg" alt="">
        <img src="../../assets/wallet-icons/image 18.svg" alt="">
        <img src="../../assets/wallet-icons/image 19.svg" alt="">
        <img src="../../assets/wallet-icons/image 20.svg" alt="">
        <img src="../../assets/wallet-icons/image 21.svg" alt="">
        <img src="../../assets/wallet-icons/image 22.svg" alt="">
        <img src="../../assets/wallet-icons/image 23.svg" alt="">
        <img src="../../assets/wallet-icons/image 24.svg" alt="">
        <img src="../../assets/wallet-icons/image 25.svg" alt="">
        <img src="../../assets/wallet-icons/image 26.svg" alt="">
        <img src="../../assets/wallet-icons/image 27.svg" alt="">
        <img src="../../assets/wallet-icons/image 28.svg" alt="">
        <img src="../../assets/wallet-icons/image 29.svg" alt="">
        <img src="../../assets/wallet-icons/image 30.svg" alt="">
        <img src="../../assets/wallet-icons/image 31.svg" alt="">
        <img src="../../assets/wallet-icons/image 32.svg" alt="">
        <img src="../../assets/wallet-icons/image 33.svg" alt="">
        <img src="../../assets/wallet-icons/image 3.svg" alt="">
        <img src="../../assets/wallet-icons/image 36.svg" alt="">
    </div>
</template>

<script>
    export default {
        name:'Coins',

    }
</script>

<style lang="scss" scoped>
.coins{
    z-index: 10;
    // background: red;
    display: flex;
    flex-direction: row;
    column-gap: 100px;
    row-gap: 32px;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 80px 64px;
    align-items: center;
}
</style>